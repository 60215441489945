import "./App.css";
import logo from "./assets/logo.png";

function App() {
  const openTelegramLink = () => {
    window.open("https://t.me/Artilego", "_blank");
  };
  return (
    <div className="page">
      <div className="container">
        <div>
          <h1>Our website is almost ready!</h1>
          <div className="content">
            <p>
              We're putting the finishing touches on our website to bring you an
              amazing experience. Stay tuned for the big reveal!
            </p>
            <p>
              In the meantime, if you have any questions or want to connect with
              us, feel free to reach out via Telegram by clicking the button
              below
            </p>
            <button onClick={openTelegramLink}>Contact Us on Telegram</button>
            <p>
              or write us on <span>artilego@proton.me</span>
            </p>
          </div>
        </div>
        <div className="footer">
          <div className="agency">
            <img src={logo}></img>
            <div className="label">
              <h2>MVP Development Agency For Startups and Businesses</h2>
              <h3>© 2024 Artilego Lab</h3>
            </div>
          </div>
          <h3 id="contactUs">Contact Us on Telegram</h3>
        </div>
      </div>
    </div>
  );
}

export default App;
